<template>
  <a-card :bordered="false">
    <div style="margin-top:0;">

      <a-button style="width:10%; float:left; margin-right:10px" @click="goEdit">新增</a-button>
      <a-input
              v-model="methodParam.code"
              style="width:32%; float:left; margin-right:10px"
              placeholder="检测方法代码" />
      <a-input
              v-model="methodParam.nameStr"
              style="width:32%; float:left"
              placeholder="检测方法名称" />
      <a-button type="primary" style="width:10%; margin-left:10px" @click="getMethod">查询</a-button>
      <a-button style="width:10%; margin-left:10px; float:right" @click="goBack">返回</a-button>
      <div style="clear:both"></div>
    </div>
    <div style="width:100%; height:1px; background-color:#ccc; margin:20px 0"></div>
    <a-table
            :columns="column"
            :dataSource="methodList"
            :pagination="pagination"
            @change="handleTableChange"
    >
      <template slot="serial" slot-scope="text, record, index">
        <template>{{ index + 1 }}</template>
      </template>
      <template v-for="col in ['method', 'code', 'standards']" :slot="col" slot-scope="text, record, index">
        <a-input
                :key="col"
                v-if="record.editable"
                style="margin: -5px 0"
                :value="text"
                @change="e => tableChange(e.target.value, col, index)"
        />
        <template v-else>{{ text }}</template>
      </template>
      <template slot="action" slot-scope="text, record, index">
        <a @click="editItem(index)" v-if="!record.editable">编辑</a>
        <a @click="updateItem(index)" v-if="record.editable" style="color:#ff3300; font-weight:bold">保存</a>
        <a-divider type="vertical" />
        <a @click="delItem(record.id)">删除</a>
      </template>
    </a-table>
  </a-card>
</template>

<script>
import { STable, Ellipsis } from '@/components'
import { getMethodList, updateMethod, deleteMethod } from '@/api/quality/item'

export default {
  name: 'TableList',
  components: {
    STable,
    Ellipsis
  },
  data () {
    return {
      pagination: {
        total: 0,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '15', '20'],
        showTotal: total => `共有 ${total} 条数据`
      },
      nowPage: 1,
      // create model
      visible: false,
      confirmLoading: false,
      mdl: null,
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      methodParam: {
        code: '',
        nameStr: ''
      },
      selectedRowKeys: [],
      selectedRows: [],
      // 返回数据
      methodList: [],
      // table
      column: [
        {
          title: '#',
          dataIndex: 'serial',
          key: 'serial',
          width: '10%',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '检验方法名称',
          dataIndex: 'method',
          key: 'method',
          width: '25%',
          scopedSlots: { customRender: 'method' }
        },
        {
          title: '检验方法代码',
          dataIndex: 'code',
          key: 'code',
          width: '25%',
          scopedSlots: { customRender: 'code' }
        },
        {
          title: '检验方法标准',
          dataIndex: 'standards',
          key: 'standards',
          width: '20%',
          scopedSlots: { customRender: 'standards' }
        },
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          width: '20%',
          scopedSlots: { customRender: 'action' }
        }
      ]
    }
  },
  filters: {},
  created () {
    this.getMethod()
  },
  computed: {
  },
  methods: {
    goEdit () {
      this.$router.push({
        path: '/test/method/edit'
      })
    },
    goBack () {
      this.$router.back()
    },
    getMethod () {
      getMethodList(this.methodParam).then(response => {
        this.methodList = response.data.list
      })
    },
    editItem (index) {
      console.log(this.pagination)
      var list = this.methodList
      list[index + (this.nowPage - 1) * this.pagination.pageSize].editable = true
      this.methodList = []
      this.methodList = list
      console.log(index)
    },
    updateItem (index) {
      updateMethod(this.methodList[index + (this.nowPage - 1) * this.pagination.pageSize]).then(response => {
        var list = this.methodList
        list[index + (this.nowPage - 1) * this.pagination.pageSize].editable = false
        this.methodList = []
        this.methodList = list
        this.$message.info('保存成功')
      })
    },
    delItem (id) {
      var self = this
      this.$confirm({
        title: '确认删除',
        content: `是否确认删除当前检测方法？`,
        cancelText: '取消',
        okText: '确定',
        okType: 'danger',
        onOk () {
          return new Promise((resolve, reject) => {
            deleteMethod({ id: id }).then(response => {
              self.$message.error('删除成功')
              self.getMethod()
            }).catch((e) => {
              // self.$message.error('删除成功')
            }).finally(() => {
              resolve()
            })
          })
        }
      })
    },
    // 操作方法
    tableChange (value, col, i) {
      this.methodList[i + (this.nowPage - 1) * this.pagination.pageSize][col] = value
    },
    handleTableChange (pagination) {
      // console.log(pagination)
      this.nowPage = pagination.current
    }
  }
}
</script>
